* {
  color:grey;
}

.App {
  background-color: gainsboro;
  min-height: 100vh;
}

.App-logo {
  height: 12vmin;
}

.App-header {
  background-color: #bab6b7;
  height: 13vh;
  width:100%;
  display:flex;
  font-size: calc(10px + 2vmin);
}

.graph {
  margin:5%;
}

h2 {
  padding-bottom:3%;
}

.container {
  display:flex;
  margin: 3% 10% ;
}

.pie {
  margin-top:17%;
  margin-left:20%;
  color:black;
}

a {
  color: blue;
}

p {
  margin-bottom: 5%;
}